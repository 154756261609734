import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        component: () => import('./views/components/Index'),
        children: [
            {
                name: 'Portada',
                path: '',
                component: () => import('./views/components/portada')
            }

        ]
    },
    {
        path: '/dashboard',
        component: () => import('./views/components/Index'),
        children: [
            {
                name: 'Alta Router',
                path: '',
                component: () => import('./views/components/alta')
            },
            {
                name: 'Histórico',
                path: '/historico',
                component: () => import('./views/historico')
            },
            {
                name: 'Editar Router',
                path: '/editarRouter',
                component: () => import('./views/components/editarRouter')
            }

        ]
    },
    {
        path: '/menu',
        component: () => import('./views/components/Index'),
        children: [
            {
                name: 'Menú',
                path: '',
                component: () => import('./views/components/menu')
            }

        ]
    }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
