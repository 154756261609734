import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import axios from 'axios'
import i18n from './i18n'

Vue.config.productionTip = false
Vue.use(VueRouter)

Vue.prototype.$axios = axios

new Vue({
    router,
    store,
    vuetify,
    i18n,
    axios,
    render: h => h(App)
}).$mount('#app')
