import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

import es from '../locales/es.ts'

Vue.use(Vuetify)
const messages = {
    es: {
        $vuetify: {
            dataIterator: {
                rowsPerPageText: 'Elementos por página:'
                // pageText: '{0}-{1} de {2}'
            },
            dataFooter: {
                itemsPerPageText: 'Elementos por página:',
                itemsPerPageAll: 'Todos',
                nextPage: 'Siguiente página',
                prevPage: 'Página anterior',
                firstPage: 'Primera página',
                lastPage: 'Última página'
            }
        }
    }
}

export default new Vuetify({
    lang: {
        locales: { es },
        current: 'es',
        messages
    }
})
