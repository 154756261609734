import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        auth: {
            loggedIn: false,
            username: '',
            token: ''
        },
        Historico: [],
        CircuitoFtth: '',
        OntSerial: ''
    },
    mutations: {
        SET_TOKEN (state, token) {
            state.auth.token = token
        },
        SET_LOGGED_IN (state, estado) {
            state.auth.loggedIn = estado
        },
        SET_USERNAME (state, username) {
            state.auth.username = username
        },
        SET_HISTORICO (state, Historico) {
            state.Historico = Historico
        },
        SET_CIRCUITOFTTH (state, CircuitoFtth) {
            state.CircuitoFtth = CircuitoFtth
        },
        SET_ONTSERIAL (state, OntSerial) {
            state.OntSerial = OntSerial
        }
    },
    getters: {
        getToken (state) {
            return state.auth.token
        },
        isLoggedIn (state) {
            return state.auth.loggedIn
        },
        getUsername (state) {
            return state.auth.username
        },
        getHistorico (state) {
            return state.Historico
        },
        getCircuitoFtth (state) {
            return state.CircuitoFtth
        },
        getOntSerial (state) {
            return state.OntSerial
        }
    },
    actions: {
        setToken (context, token) {
            context.commit('SET_TOKEN', token)
        },
        setLoggedIn (context) {
            context.commit('SET_LOGGED_IN', true)
        },
        setLoggedOut (context) {
            context.commit('SET_LOGGED_IN', false)
        },
        setUsername (context, username) {
            context.commit('SET_USERNAME', username)
        },
        setHistorico (context, Historico) {
            context.commit('SET_HISTORICO', Historico)
        },
        setCircuitoFtth (context, CircuitoFtth) {
            context.commit('SET_CIRCUITOFTTH', CircuitoFtth)
        },
        setOntSerial (context, OntSerial) {
            context.commit('SET_ONTSERIAL', OntSerial)
        }

    }
})
